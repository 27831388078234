/** @jsx jsx */
import {Box, Flex, jsx, Styled} from 'theme-ui'
import Layout from "../components/layout"
import {UpDown, UpDownWide} from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg";

const Impressum = () => (

    <Layout>
      <div sx={{position: 'absolute', top:'0px', left:'0px', width: '100vw', height: '100vh', zIndex: 1, color: `text`, fontWeight: `semibold`, a: {color: `text`},}}>
        <Flex sx={{justifyContent: `center`, alignItems: `center`, height: '100%'}}>
          <Flex sx={{justifyContent: `center`, alignItems: `start`, flexWrap: 'wrap'}}>
            <Styled.h3 sx={{width: "300px", mb:"50px"}}>Impressum</Styled.h3>
            <Box css={{width: "300px"}}>
              Daniel Kleinert <br/>
              Sternbergstr. 15 <br/>
              76131 Karlsruhe <br/>
              Germany <br/>
              <br/>
              +49 721 90 981 444 <br/>
              <a href="mailto:support@pristinebytes.com">support@pristinebytes.com</a><br/>
              <br/>
              Sales tax ID: DE326707848 <br/>
            </Box>
          </Flex>
        </Flex>
      </div>


      <div sx={{position: 'absolute', zIndex: 0, width: '100%', height: '80vh', top: '0'}}>
        <UpDown>
          <SVG icon="box" width={6} color="icon_brightest" left="85%" top="75%"/>
          <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="20%"/>
          <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%"/>
          <SVG icon="circle" hiddenMobile width={24} color="icon_gray" left="7%" top="60%"/>
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} color="icon_green" left="20%" top="90%"/>
          <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="30%"/>
          <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%"/>
          <SVG icon="triangle" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%"/>
          <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%"/>
          <SVG icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="10%"/>
        </UpDownWide>
      </div>

    </Layout>
)

export default Impressum
